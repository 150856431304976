import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../HomeLayout/HomeLayout"
import CoolLightbox from "../LightBox/CoolLightBox"
const Page1 = ({onChange}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      Page1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "ProductionPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      ProductionInfoPage1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "ProductionInfoPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  var images = []
  data.ProductionInfoPage1.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })


  return (
    <HomeLayout>
        <>
          {data.Page1.nodes.map((img, index) => {
            var idx =index
            if(idx==2 ||idx==3||idx==0||idx==1)
            return (
              <div
                onClick={() => {
                  setCurrentIndex(idx)
                  setOpen(true)
                  onChange(false)
                }}
              >
                <Img fluid={img.childImageSharp.fluid} />
              </div>
            )
            else return <Img fluid={img.childImageSharp.fluid} />
          })}
          <CoolLightbox 
            images={images}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            isOpen={isOpen}
            onClose={() => {setOpen(false)
              onChange(true)}}
          />
          
        </>
        </HomeLayout>
  )
}
export default Page1

